.auth-form {
  margin: auto;
  padding: 2%;
  width: 30%;
  border-radius: 20px;
  margin-top: 70px;
  box-shadow: 1px 2px 10px 2px #1873E0;
  text-align: center;
}

.auth-form h5 {
  margin: 15px;
}

.auth-form p {
  margin: 20px;
}

.auth-pages {
  border-bottom: 1px solid #c6c2c2;
  border-top: 1px solid #c6c2c2;
  padding: 10px 0;
  margin: 5px;
}

#vemas_logo {
  width: 200px;
}

#vemas_logo_default {
  width: 500px;
}

.vemas_logo {
  padding: 0 2% 2% 2%;
  text-align: center;
}

.active_modal_width {
  min-width: 70%;
  max-height: calc(100% - 500px);
}

.payment-method {
  margin: 2% 10%;
}

#vemaslogo {
  margin: 2px;
  width: 200px;
  border-bottom: 1px solid #000000;
}

.confirm-code {
  color: #1873E0;
  cursor: pointer;
}

.authErrorMassage {
  color: #f80217;
  padding: 5px 20px;
  background: #f8d7da;
  border-left: 10px solid #eb5461;
  border-radius: 5px;
  /* display: flex;
justify-content: center; */
}

#messageError {
  /* position:absolute; */
  /* left:20px; */
  /* top:50%;
transform:translateY(-50%); */
  float: left;
  color: #f80217;
  font-size: 10px
}


.step-wizard {
  /* background-color: #21d4fd;
background-image: linear-gradient(19deg, #21d4fd 0%, #b721ff 100%);
height: 100vh; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.step-wizard-list {
  /* background: #fff;
box-shadow: 0 15px 25px rgba(0,0,0,0.1);
color: #333;
list-style-type: none;
border-radius: 10px; */
  display: flex;
  padding: 10px;
  position: relative;
  z-index: 10;
}

.step-wizard-item {
  padding: 0 20px;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  min-width: 170px;
  position: relative;
}

.step-wizard-item+.step-wizard-item:after {
  content: "";
  position: absolute;
  left: 0;
  top: 19px;
  background: #21d4fd;
  width: 100%;
  height: 2px;
  transform: translateX(-50%);
  z-index: -10;
}

.progress-count {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: 600;
  margin: 0 auto;
  position: relative;
  z-index: 10;
  color: transparent;
}

.progress-count:after {
  content: "";
  height: 40px;
  width: 40px;
  background: #21d4fd;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  z-index: -10;
}

.progress-count:before {
  content: "";
  height: 10px;
  width: 20px;
  border-left: 3px solid #fff;
  border-bottom: 3px solid #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -60%) rotate(-45deg);
  transform-origin: center center;
}

.progress-label {
  font-size: 14px;
  font-weight: 600;
  margin-top: 10px;
}

.current-item .progress-count:before,
.current-item~.step-wizard-item .progress-count:before {
  display: none;
}

.current-item~.step-wizard-item .progress-count:after {
  height: 10px;
  width: 10px;
}

.current-item~.step-wizard-item .progress-label {
  opacity: 0.5;
}

.current-item .progress-count:after {
  background: #fff;
  border: 2px solid #21d4fd;
}

.current-item .progress-count {
  color: #21d4fd;
}

@media only screen and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .auth-form {
    width: 70%;
    padding: 3%;
  }
}

@media only screen and (max-device-width: 769px) {
  .auth-form {
    width: 90%;
    padding: 3%;
  }
}