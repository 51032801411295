header{
    background-color: #4278b7;
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
  }

  #icon{
    color: #ffffff;
    cursor: pointer;
    margin: 20px;
    width:1.2rem;
    height:1.2rem;
  } 

  #icon-menu{
    color: #ffffff;
    cursor: pointer;
    width:1.2rem;
    height:1.2rem;
  }

  #icon:hover{
    color: blanchedalmond;
  }

  #side-icon{
    color: #ffffff;
    width: 1.8rem;
    height: 1.8rem;
  }

  .side-bar{
    background-color: #3a5371;
    position: absolute;
    left: 0;
    width:14%;
    height: calc(100vh - 60px);
  }

  /* .side-bar a{
    text-decoration: none;
    box-sizing: border-box;
    width: 100%;
    line-height: 70px;
    padding-left: 40px;
    display: block;
    transition: 0.5s;
    transition-property: background;
  } */


  .text-box{
    list-style: none;
    font-size: large;
  }

  .nav_text{
    list-style: none;
    font-size: 1rem;
    color: #ffffff;
    font-weight: 400;
    text-decoration: none;
    box-sizing: border-box;
    width: 100%;
    line-height: 70px;
    padding-left: 40px;
    display: block;
    transition: 0.5s;
    transition-property: background;
  }

  .profileImageView{
    width: 150px;
    height: 150px;
  }

  .userImage{
    width: 40px;
    height: 40px;
    border:1px solid #4278b7;
    margin: 5px;
    cursor: pointer;
}

  .nav_text:hover{
    background-color: #0c2849;
    color: #ffffff;
    font-weight: 700;
    text-decoration: none;
  }

  .nav_text_active{
    list-style: none;
    font-size: 1rem;
    color: #ffffff;
    text-decoration: none;
    box-sizing: border-box;
    width: 100%;
    line-height: 70px;
    padding-left: 40px;
    display: block;
    transition: 0.5s;
    transition-property: background;
    background-color: #0f3868;
    font-weight: 700;
  }

  .nav_text_active:hover{
    color: #ffffff;
    font-weight: 700;
    text-decoration: none;
  }

  .nav_text_active span{
    margin: 15px;
  }

  .nav_text span{
    margin: 15px;
  }

  .side-bar-icon{
    background-color: #3a5371;
    position: absolute;
    left: 0;
    width:4%;
    min-height: calc(100vh - 60px);
  }

  .side-bar-icon a{
    text-decoration: none;
    box-sizing: border-box;
    width: 100%;
    line-height: 70px;
    padding-left: 10px;
    display: block;
    transition: 0.5s;
    transition-property: background;
  }

  .side-bar-icon a:hover{
    background-color: #0c2849;
    color: #ffffff;
    font-weight: 700;
  }

  .nav_right{
    float: right;
    margin-left: auto;
    align-items: center;
    display: flex;
  }

  .nav_left{
    float: left;
    display: flex;
    margin-right: auto;
    align-items: center;
  }

  .sidebar-menu{
    margin-left: 10px;
  }

  .setting_menu {
    width:600px;
    height: auto;
    background-color: #ffffff;
    box-shadow:2px 2px 5px #333;
    /* border: 2px solid #333; */
    padding: 10px 20px;
    margin-right: 150px;
    float: right;
    z-index: 1;
    line-height: 25px;
    right: 0;
    position: fixed;
    padding-bottom: 20px;
    /* display: none; */
  }

  .setting_menu h5{
    font-size: 1rem;
    margin-top: 10px;
    border-bottom: 1px solid #afafaf ;
    padding-bottom: 5px;
  }

  .user_menu {
    width:300px;
    height: auto;
    background-color: #ffffff;
    box-shadow:2px 2px 5px #333;
    /* border: 2px solid #333; */
    padding: 10px 20px;
    margin-right: 20px;
    float: right;
    z-index: 1;
    line-height: 25px;
    right: 0;
    position: fixed;
    padding-bottom: 20px;
  }

  /* .navbar_custom_menu {
    float:right;
    margin-top: -50px;
  } */

  .setting_text{
    list-style: none;
    font-size: small;
    text-decoration: none;
  }

  /* h5{
    border-bottom: solid #7f808a 1px;
    padding-top: 10px;
    font-size: 1rem;
  } */

  #navlogo{
    padding: 2px;
    margin: 2px;
    margin-left: 10px;
    width: 100%;
    height: auto;
    /* width: 10%; */
  }

  @media only screen 
  /* and (min-device-width: 768px)  */
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    #side-icon{
      width: 1.2rem;
      height: 1.2rem;
    }
    .side-bar a{
      padding-left: 10px;
      /* width: 50%; */
    }

    .nav_text{
      font-size: 0.8rem;
      color: #ffffff;
      font-weight: 200;
    }

    .nav_text_active{
      font-size: 0.8rem;
      color: #ffffff;
      font-weight: 200;
    }

    .nav_text_active span{
      margin: 5px;
    }
  
    .nav_text span{
      margin: 5px;
    }

    .side-bar-icon{
      width: 5%;
    }

    .setting_menu {
      margin-right: 50px;
    }

    #icon-menu{
      margin: 20px;
    }
}

