.content-header {
  background-color: #ffffff;
  height: 50px;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.images-view-header {
  background-color: #ffffff;
  height: 50px;
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.header-pages {
  display: flex;
  justify-content: space-around;
}

.header-pages a {
  text-decoration: none;
}

.header-pages span {
  margin: 0 10px;
  cursor: pointer;
  color: #4278b7;
}

.header-btn {
  float: right;
  /* margin-bottom: 10px; */
}

.content-body {
  /* border-top: 2px solid #4278b7; */
  margin-top: 5px;
  background-color: #ffffff;
}

.content-body h5 {
  margin-top: 10px;
  padding: 5px 0 0 5px;
  font-size: 1.1rem;
}

.filter-header {
  display: flex;
  justify-content: space-between;
}

.address-title {
  font-size: 0.9rem;
  color: #4278b7;
  font-weight: 500;
  margin-right: 15px;
}

.disabledbutton {
  pointer-events: none;
  opacity: 0.4;
}

.email-form {
  /* border-bottom:solid 2px #dbd8d8; */
  margin: 5px;
  padding: 2px;
}

#notification-bell {
  width: 1.3rem;
  height: 1.3rem;
  color: #e01829;
  margin: 10px;
  -webkit-animation: ring 4s .7s ease-in-out infinite;
  -webkit-transform-origin: 50% 4px;
  -moz-animation: ring 4s .7s ease-in-out infinite;
  -moz-transform-origin: 50% 4px;
  animation: ring 4s .7s ease-in-out infinite;
  transform-origin: 50% 4px;
}

@-webkit-keyframes ring {
  0% {
    -webkit-transform: rotateZ(0);
  }

  1% {
    -webkit-transform: rotateZ(30deg);
  }

  3% {
    -webkit-transform: rotateZ(-28deg);
  }

  5% {
    -webkit-transform: rotateZ(34deg);
  }

  7% {
    -webkit-transform: rotateZ(-32deg);
  }

  9% {
    -webkit-transform: rotateZ(30deg);
  }

  11% {
    -webkit-transform: rotateZ(-28deg);
  }

  13% {
    -webkit-transform: rotateZ(26deg);
  }

  15% {
    -webkit-transform: rotateZ(-24deg);
  }

  17% {
    -webkit-transform: rotateZ(22deg);
  }

  19% {
    -webkit-transform: rotateZ(-20deg);
  }

  21% {
    -webkit-transform: rotateZ(18deg);
  }

  23% {
    -webkit-transform: rotateZ(-16deg);
  }

  25% {
    -webkit-transform: rotateZ(14deg);
  }

  27% {
    -webkit-transform: rotateZ(-12deg);
  }

  29% {
    -webkit-transform: rotateZ(10deg);
  }

  31% {
    -webkit-transform: rotateZ(-8deg);
  }

  33% {
    -webkit-transform: rotateZ(6deg);
  }

  35% {
    -webkit-transform: rotateZ(-4deg);
  }

  37% {
    -webkit-transform: rotateZ(2deg);
  }

  39% {
    -webkit-transform: rotateZ(-1deg);
  }

  41% {
    -webkit-transform: rotateZ(1deg);
  }

  43% {
    -webkit-transform: rotateZ(0);
  }

  100% {
    -webkit-transform: rotateZ(0);
  }
}

@-moz-keyframes ring {
  0% {
    -moz-transform: rotate(0);
  }

  1% {
    -moz-transform: rotate(30deg);
  }

  3% {
    -moz-transform: rotate(-28deg);
  }

  5% {
    -moz-transform: rotate(34deg);
  }

  7% {
    -moz-transform: rotate(-32deg);
  }

  9% {
    -moz-transform: rotate(30deg);
  }

  11% {
    -moz-transform: rotate(-28deg);
  }

  13% {
    -moz-transform: rotate(26deg);
  }

  15% {
    -moz-transform: rotate(-24deg);
  }

  17% {
    -moz-transform: rotate(22deg);
  }

  19% {
    -moz-transform: rotate(-20deg);
  }

  21% {
    -moz-transform: rotate(18deg);
  }

  23% {
    -moz-transform: rotate(-16deg);
  }

  25% {
    -moz-transform: rotate(14deg);
  }

  27% {
    -moz-transform: rotate(-12deg);
  }

  29% {
    -moz-transform: rotate(10deg);
  }

  31% {
    -moz-transform: rotate(-8deg);
  }

  33% {
    -moz-transform: rotate(6deg);
  }

  35% {
    -moz-transform: rotate(-4deg);
  }

  37% {
    -moz-transform: rotate(2deg);
  }

  39% {
    -moz-transform: rotate(-1deg);
  }

  41% {
    -moz-transform: rotate(1deg);
  }

  43% {
    -moz-transform: rotate(0);
  }

  100% {
    -moz-transform: rotate(0);
  }
}

@keyframes ring {
  0% {
    transform: rotate(0);
  }

  1% {
    transform: rotate(30deg);
  }

  3% {
    transform: rotate(-28deg);
  }

  5% {
    transform: rotate(34deg);
  }

  7% {
    transform: rotate(-32deg);
  }

  9% {
    transform: rotate(30deg);
  }

  11% {
    transform: rotate(-28deg);
  }

  13% {
    transform: rotate(26deg);
  }

  15% {
    transform: rotate(-24deg);
  }

  17% {
    transform: rotate(22deg);
  }

  19% {
    transform: rotate(-20deg);
  }

  21% {
    transform: rotate(18deg);
  }

  23% {
    transform: rotate(-16deg);
  }

  25% {
    transform: rotate(14deg);
  }

  27% {
    transform: rotate(-12deg);
  }

  29% {
    transform: rotate(10deg);
  }

  31% {
    transform: rotate(-8deg);
  }

  33% {
    transform: rotate(6deg);
  }

  35% {
    transform: rotate(-4deg);
  }

  37% {
    transform: rotate(2deg);
  }

  39% {
    transform: rotate(-1deg);
  }

  41% {
    transform: rotate(1deg);
  }

  43% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(0);
  }
}

.header-icon {
  margin: 10px 10px 0 0;
  background-color: #e6e6e6;
  border-radius: 5px;
}

.header-icon :hover {
  cursor: pointer;
}

#header-icon {
  color: #4278b7;
  width: 1.5rem;
  height: 1.5rem;
  padding: 3px;
  padding-top: 5px;
  margin-bottom: -2px;
}

#header-icon :hover {
  cursor: pointer;
}

.header-icon-disabled {
  margin: 10px 10px 0 0;
  background-color: #e6e6e6;
  border-radius: 5px;
}

.header-icon-disabled :hover {
  cursor: not-allowed;
}

#table-icon-disabled {
  color: #4278b7;
  width: 1.5rem;
  height: 1.5rem;
  padding: 3px;
  margin: 3px 0;
}

#table-icon-disabled:hover {
  cursor: not-allowed;
}

.notification-red {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #e01829;
  margin-right: -30px;
  margin-top: 5px;
}

.FixedHeightContainer {
  /* float:right; */
  height: 250px;
  width: 100%;
  overflow-y: scroll;
}

/*SCROLLBAR MODIFICATIONS*/

.FixedHeightContainer::-webkit-scrollbar {
  width: 8px;
}

.FixedHeightContainer::-webkit-scrollbar-thumb {
  background: #909090;
  border-radius: 8px;
}

.FixedHeightContainer::-webkit-scrollbar-track {
  background: #FFFFFF;
}

.shipping-address {
  display: flex;
  /* justify-content: space-around; */
}

.page_header {
  border: solid 2px #dbd8d8;
  margin: 0 15px;
  border-radius: 5px;
  padding: 0 30px 20px 30px;
  margin-bottom: 10px;
}

.page_header-title {
  margin: 15px 0;
  border-bottom: solid 2px #dbd8d8
}

.page_header-title span {
  font-size: 1rem;
  font-weight: 500;
  color: #4278b7;
}

.invoice-title {
  margin: 15px 0 0 20px;
  display: flex;
  justify-content: space-between;
}

.invoice-title span {
  font-size: 1rem;
  font-weight: 500;
  color: #4278b7;
}

#decline {
  width: 1.3rem;
  height: 1.3rem;
  color: #1873E0;
  margin: 10px;
}

.value {
  margin: 10px 0
}

.value-title {
  margin-top: 10px;
}

.modal_width {
  width: 90vw
}

.headerBtn {
  display: flex;
  justify-content: flex-end;
  background-color: #ffffff;
  height: 50px;
  margin-top: -50px;
  align-items: center;
  padding-right: 10px;
}

/* .dropbtn {
  background-color: #3498DB;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
} */

/* .dropup {
  position: relative;
  display: inline-block;
}

.dropup-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  bottom: 50pxs;
  z-index: 1;
}

.dropup-content span {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropup-content span:hover {background-color: #ccc}

.dropup:hover .dropup-content {
  display: block;
} */

/* .dropup:hover .dropbtn {
  background-color: #2980B9;
} */


.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  right: 0;
  background-color: #f9f9f9;
  min-width: 180px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content li {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

.dropdown-content li:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-page {
  color: black;
  text-decoration: none;
}

.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}

.table-invoice {
  background-color: #ffffff;
  margin-bottom: 10px;
  padding: 10px 20px 10px 20px;
  margin: auto;
  width: 100%;
  display: block;
  /* border-bottom:solid 2px #c6c2c2;
  border-top:solid 2px #c6c2c2 */
}

.signature-image {
  width: 100%;
  /* border:1px solid #ced4da; */
  margin-bottom: 10px;
}

.signature {
  width: 100%;
  border: 1px solid #ced4da;
  margin-bottom: 10px;
  min-height: 300px;
}

.dashBoard {
  margin: 1%;
}

.card-list {
  display: grid;
  grid-template-columns: repeat(3, 2fr);
  /* flex-direction: row; */
  justify-content: space-around;
  flex-flow: wrap;
  margin: 0 -1%;
}

.chart-view {
  background-color: #ffffff;
  /* display: flex;
    justify-content: space-between; */
  display: grid;
  grid-template-columns: 70% 30%;
  margin-top: 20px;
  height: 400px;
  width: 100%;
}

.chart-content {
  padding: 10px;
  height: 400px;
  /* margin: 5px; */
  border: 1px solid #969696;
  /* text-align: center; */
}

.pieChart-content {
  text-align: center;
  padding: 10px;
  height: 400px;
  /* margin: 5px; */
  border: 1px solid #969696;
  margin-left: 10px;
}

.pieChart-content h5 {
  margin-bottom: 20px;
}

.aligncenter {
  float: left;
  text-align: right;
  width: 60%;
}

.alignright {
  float: right;
  text-align: right;
  width: 30%;
  margin-top: -20px;
}

.analytics-chart {
  background-color: #ffffff;
  /* display: flex;
  justify-content: space-between; */
  display: grid;
  grid-template-columns: 100%;
  margin-top: 5px;
  height: 350px;
  width: 100%;
}

.customer-analytics-chart{
  background-color: #ffffff;
  display: grid;
  grid-template-columns: 100%;
  margin-top: 5px;
  height: 500px;
  width: 100%;
}

.analytics-content {
  padding: 10px;
  height: 350px;
  margin: 5px;
  border: 1px solid #969696;
  /* text-align: center; */
}

.notification-content {
  margin: 2px;
  margin-top: 5px;
  background-color: #ffffff
}

.email-view {
  right: 10;
  width: 55%;
  height: 80vh;
  overflow-y: auto;
  position: absolute;
  margin: 5px 10px 0 10px;
}

.notification-header {
  display: flex;
  margin: 5px;
  border-bottom: 1px solid #cecece;
  padding: 10px;
}

.notification-header h6 {
  font-weight: 700;
  margin: 5px 5px 5px 10px;
}

.filter-form-notification {
  border-top: 2px solid #d2d6de;
  border-bottom: 2px solid #d2d6de;
  /* margin: 0 0 10px 0; */
  padding: 10px;
  margin-bottom: 10px;
  background-color: #ffffff;
}

.notification-list {
  width: 30%;
  /* margin-right: 10px; */
  background-color: #ffffff;
  border: 1px solid #d2d6de;
  border-radius: 10px;
  height: 70vh;
  overflow-y: auto;
  position: relative;
  color: #000000;
}

.email-body {
  background-color: #ffffff;
  border: 1px solid #d2d6de;
  border-radius: 10px;
  height: 30vh;
  overflow-y: auto;
  position: relative;
  color: #000000;
  width: 100%;
}

.notification-view {
  right: 0;
  width: 100%;
  /* height: 65vh;
overflow-y: auto; */
  position: static;
  margin: 0 0 0 10px;
  background-color: #ffffff;
  padding: 10px;
  border: 1px solid #d2d6de;
  border-radius: 10px;
  color: #000000;
}

.notification-card {
  padding: 5px 0 0 5px;
  cursor: pointer;
  border-bottom: 1px solid #cecece;
  /* border-top: 1px solid #cecece; */
  display: flex;
}

.notification-card-avatar {
  width: 20%;
  margin-right: 2px;
}

.notification-card-content {
  width: 80%;
}

.sms-view {
  padding: 5px;
  cursor: pointer;
  border: 1px solid #cecece;
  border-radius: 10px;
  margin: 1%;
  display: flex;
}

.sms-avatar {
  width: 3%;
  margin: 10px;
}

.sms-details {
  width: 70%;
  margin: 10px;
}

.status-dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin-right: 10px;
}

#action-icon {
  color: #000000;
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 3px;
}

#action-icon:hover {
  cursor: pointer;
}

.method-card-list{
  display: grid;
  grid-template-columns: repeat(3, 2fr);
  /* flex-direction: row; */
  justify-content: space-around;
  flex-flow: wrap;
  margin: 0 -1%;
  margin-bottom: 7%;
}

.confirm-details{
  text-align: left;
}

.confirm-title{
  text-align: right;
  font-weight: 700;
  margin:0 5px;
}

/* .action-title{
ma
} */

@media print {
  * {
    display: none;
  }

  iframe {
    display: block;
    width: 100%;
    height: 100%;
  }
}

@media only screen and (min-device-width: 769px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .card-list {
    grid-template-columns: repeat(2, 2fr);
    margin: 0 -2%;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 769px) {
  .card-list {
    grid-template-columns: repeat(2, 2fr);
    margin: 1%;
    margin: 0 -2.5%;
  }
}